import React, { useState, useEffect, useCallback } from 'react';
import './modal.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PearsonLogo from '../../../images/pearson_logo.png';
import iconFolder from '../../../images/icon_folder.gif';
import fileIcon from '../../../images/icon_file.gif';
import ProgressBarGif from '../../../images/prog_bar.gif';
import ProgressBarTopGif from '../../../images/prog_top.gif';
import ProgressBarLeftGif from '../../../images/prog_left.gif';

import '../../common/css/pw_push5.css';
import { getSubDirectories, downloadBulkFilesInZip, resetBulkUploadStateManageMent } from '../../Dashboard/actions';
import Loader from "../../common/Loader";
import { updateGenricSuccessMessageToEmpty } from '../../User/actions';

const BulkDownloadPopup = (props) => {
  const [filesLists, setFilesList] = useState([]);
  const dispatch = useDispatch();
  const location = useLocation();
  const [disableCheckbox, setDisableCheckbox] = useState(true);
  const [loading, setLoading] = useState(false);
  const [deleteFilesArray, setDeleteFilesArray] = useState([]);
  const [downloadLoaderVisible, setDownloadLoaderVisible] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState('0%'); // Track download progress
  const [wsTransmittedReceivedData, setWsTransmittedReceivedData] = useState(0); // Track bytes transmitted
  const [netSpeed, setNetSpeed] = useState(0); // Track network speed
  const [timeRemaining, setTimeRemaining] = useState(0); // Estimate remaining time
  const [elapsedTime, setElapsedTime] = useState(0); // Track elapsed time
  const [isDownloading, setIsDownloading] = useState(false); // Track if download is in progress
  const [modalHeight, setModalHeight] = useState('80%'); // State for modal height


  let [itotal, setITotal] = useState(0);

  let currentURI = location.pathname;
  let currentURImatches = currentURI.match(/^\/mdc\/auth\/([^\/]+)/);
  let uriPrefex = currentURImatches ? currentURImatches[0] : '';
  let currentPath = currentURI.replace(`${uriPrefex}`, "");
  currentPath = currentPath.split('/').filter(path => path);
  currentPath = `/${currentPath.join('/')}`;
  if (currentPath === "") {
    currentPath = '/';
  }

  const directoriesList = useSelector(state => state.directoriesReducer.serverResponse);
  const downloadFileLimitMsg = useSelector(state => state.UserReducer.userSuccessMessage);
  const closeBulkDownloadPopup = useSelector(state => state.directoriesReducer.closeDownloadPopup);

  useEffect(() => {
    if (Object.entries(downloadFileLimitMsg).length > 0) {
      setDownloadLoaderVisible(false);
      props.onModalPopupStateChange(props.displayModal);
    }
    dispatch(resetBulkUploadStateManageMent({}));
  }, [downloadFileLimitMsg]);

  useEffect(() => {
    if (closeBulkDownloadPopup?.status_code == 200) {
      props.onModalPopupStateChange(props.displayModal);
    }
    dispatch(resetBulkUploadStateManageMent({}));
  }, [closeBulkDownloadPopup]);

  useEffect(() => {
    dispatch(updateGenricSuccessMessageToEmpty({}));
    setFilesList(directoriesList?.data);
    deSelectAllFiles();
    setLoading(false);
  }, [directoriesList]);

  useEffect(() => {
    setLoading(true);
    dispatch(getSubDirectories({ dir: currentPath }));
    if (currentPath !== '/') {
      setDisableCheckbox(false);
    } else {
      setDisableCheckbox(true);
    }
  }, [currentPath, dispatch]);

  const deleteFiles = (e) => {
    if (e.target.checked) {
      setDeleteFilesArray([...deleteFilesArray, e.target.value]);
    } else {
      setDeleteFilesArray(deleteFilesArray.filter(file => file !== e.target.value));
    }
  };

  const selectAllFiles = () => {
    let checkboxArray = document.getElementsByName("fileDownload[]");
    let fileArray = [];
    checkboxArray.forEach((file) => { file.checked = true; fileArray.push(file.value) });
    setDeleteFilesArray([...deleteFilesArray, ...fileArray]);
  };

  const deSelectAllFiles = () => {
    let checkboxArray = document.getElementsByName("fileDownload[]");
    checkboxArray.forEach((file) => { setDeleteFilesArray([]); file.checked = false; });
  };

  const closeModal = (e) => {
    e.stopPropagation();
    props.onModalPopupStateChange(props.displayModal);
  };

  const handleDownloadSelected = async () => {
    const pathsToDownload = deleteFilesArray.map((item) => currentPath + '/' + item);
    const encodedPaths = pathsToDownload.map(path => encodeURIComponent(path));

    if (pathsToDownload.length) {
      setDownloadLoaderVisible(true);
      setIsDownloading(true);
      //setModalHeight('85%'); // Increase the modal height
      // Simulate download progress
      simulateDownloadProgress();
      dispatch(downloadBulkFilesInZip({ 'encodedPaths': { 'encodedPaths': encodedPaths, 'dir': encodeURIComponent(currentPath) } }));
    } else {
      alert('Please Select files that you need to download');
    }
  };
  ///////
  const formatTime = (timeInSeconds) => {
    const hours = (!isNaN(timeInSeconds) && timeInSeconds != 'Infinity') ? Math.floor(timeInSeconds / 3600) : 0;
    const minutes = (!isNaN(timeInSeconds) && timeInSeconds != 'Infinity') ? Math.floor((timeInSeconds % 3600) / 60) : 0;
    const seconds = (!isNaN(timeInSeconds) && timeInSeconds != 'Infinity') ? Math.floor(timeInSeconds % 60) : 0;
    return `${String(hours).padStart(2, '0')} : ${String(minutes).padStart(2, '0')} : ${String(seconds).padStart(2, '0')}`;
  };

  // Simulate download progress (for demonstration purposes)
  const simulateDownloadProgress = () => {
    let progress = 0;
    const interval = setInterval(() => {
      if (progress < 100) {
        progress += 5;
        setDownloadProgress(progress);
        setWsTransmittedReceivedData(progress * 1024); // Simulate transmitted data in bytes
        setNetSpeed(500); // Simulated network speed (in Kbps)
        setElapsedTime(elapsedTime + 1);
        setTimeRemaining((100 - progress) * 10); // Simulated remaining time in seconds
      } else {
        clearInterval(interval);
        setIsDownloading(false);
      }
    }, 1000);
  };

  return (
    <>
      <div className="modal" onClick={closeModal}>
      <div className="modal-content" style={{ height:isDownloading?"90%":modalHeight,overflowY:"auto" }} onClick={e => e.stopPropagation()}>

          <table border="0" width="100%" cellSpacing="3" cellPadding="0" className='modal-content-table'>
            <tr align="left" valign="top">
              <td align="left" valign="middle"><span className="pagetitle">&nbsp;Download Files</span></td>
              <td align="right" valign="middle"><img src={PearsonLogo} title="Pearson Education" alt="Pearson Education" width="85" height="28" hspace="0" vspace="0" border="0" /></td>
              <td><span className="close" onClick={closeModal}>&times;</span></td>
            </tr>
          </table>

          <div className='generalcoursecontainer'>
            <div className="table-container-bulkdownload">
              <table className='myTable' border="1" width="99%" cellSpacing="1" cellPadding="4">
                <tbody>
                  <tr align="left" valign="top">
                    <th align="center" valign="middle" width="60">Download</th>
                    <th align="left" valign="middle"> &nbsp;Name</th>
                    <th align="left" valign="middle" width="100">&nbsp;Size (Bytes)</th>
                    <th align="left" valign="middle" width="120">&nbsp;Last Modified</th>
                  </tr>

                  {loading ? <tr colSpan="8"><Loader /></tr> : (
                    filesLists && filesLists.length > 0 && (
                      filesLists.map((fileList, index) => {
                        let cellStyle = "cellGreen";
                        if (index % 2 === 0) {
                          cellStyle = "cellWhite";
                        }
                        let folderIcon = iconFolder;
                        if (fileList['isFile']) {
                          folderIcon = fileIcon;
                        }
                        return (<tr align="left" valign="top" key={index}>
                          <td width="70" className={"checkboxalign " + cellStyle}>
                            <input type="checkbox" name="fileDownload[]" value={fileList['directory']} disabled={disableCheckbox} onClick={deleteFiles} />
                          </td>
                          <td className={cellStyle} nowrap="">
                            <div className="folderpointer">
                              &nbsp;<img alt={''} src={folderIcon} />&nbsp;{fileList['directory']}
                            </div>
                          </td>
                          <td className={cellStyle}>{fileList['size']}</td>
                          <td className={"checkboxalign " + cellStyle}>{fileList['last_modified_date']}</td>
                        </tr>)
                      })))}
                </tbody>
              </table>
            </div>

            {(!disableCheckbox && localStorage.getItem('user.role') !== 'Vendor') &&
              <table width="100%">
                <tr align="left" valign="top">
                  <td align="left"><button className="cellWhite formButtonGo delete-selected" onClick={selectAllFiles}>Select&nbsp;All</button>
                    <span className="cellWhite">&nbsp;|&nbsp;</span>
                    <button className="cellWhite formButtonGo delete-selected" onClick={deSelectAllFiles}>Clear&nbsp;All</button></td>
                </tr>
                <tr align="left" valign="top">
                  <td><button className="formButtonDownload" onClick={handleDownloadSelected}>Download Selected</button></td>
                </tr>
                {downloadLoaderVisible && (
                  <tr align="left" valign="top">
                    <td style={{ color: 'green' }}>
                      Please wait for a couple of seconds. Your selected files are downloading. If the download is not happening, please allow browser pop-ups for the site.
                    </td>
                  </tr>
                )}
                {isDownloading && (
                  <tr align="left" valign="top">
                    <td>
                      <table style={{ border: '0', width: '100%', backgroundColor: "#E9EAEC", marginTop: "25px" }}>
                        <tr align="center" style={{ verticalAlign: "top" }}>
                          <td align="center" style={{ verticalAlign: "middle" }} className="pagetitle" colSpan="2">Download in Progress, Please don't close window</td>
                        </tr>
                        <tr align="left" style={{ verticalAlign: "top" }}>
                          <td align="left" style={{ verticalAlign: "middle" }} colSpan="2">
                            <table summary="This table displays the progress bar" border="0" width='100%' cellSpacing="0" cellPadding="0" backgroundColor="#FFFFFF">
                              <tr align="left" style={{ verticalAlign: "top" }}>
                                <td align="left" style={{ verticalAlign: "top", paddingTop: "13px" }} rowSpan="3" width="3">
                                  <img src={ProgressBarLeftGif} alt="" width="3" height="16" hspace="0" vspace="0" border="0" />
                                </td>
                                <td align="left" style={{ verticalAlign: "top" }}>
                                  <img src={ProgressBarTopGif} alt="" width={`${downloadProgress}%`} height="4" hspace="0" vspace="0" border="0" style={{ marginBottom: "-5px" }} />
                                </td>
                              </tr>
                              <tr align="left" style={{ verticalAlign: "top" }}>
                                <td align="left" style={{ verticalAlign: "top" }}>
                                  <span>
                                    <img src={ProgressBarGif} alt="" width={`${downloadProgress}%`} height="10" hspace="0" vspace="0" border="0" />
                                  </span>
                                </td>
                              </tr>
                              <tr align="center" style={{ verticalAlign: "top" }}>
                                <td align="center" style={{ verticalAlign: "middle" }} className="instructions" colSpan="2">
                                  Status: {wsTransmittedReceivedData} bytes of {itotal} received (at {netSpeed} Kbps)
                                </td>
                              </tr>
                            </table> 
                            <table border="0" width='100%' cellSpacing="0" cellPadding="0">
                              <tr align="center" verticalAlign="top">
                                <td align="left" style={{ verticalAlign: "middle" }} className="instructions">
                                  <strong>Est Time left:&nbsp;</strong>{formatTime(timeRemaining)}
                                </td>
                                <td align="right" style={{ verticalAlign: "middle" }} className="instructions">
                                  <strong>Elapsed time:&nbsp;</strong>{formatTime(elapsedTime)}
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                )}
              </table>
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default BulkDownloadPopup;
